import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


export default function ConfirmDialog(props) {
  console.log("ConfirmDialog", props.owner)
  console.log("ConfirmDialog", props.repo)

  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpen(false)}

        >
          No
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
            onConfirm(props.owner, props.repo);
          }}

        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
