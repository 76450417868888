import React from 'react'

export const ErrorPage = () => {
   return (
    <div className='top-container'>
    <h1> Error: Page Not Found</h1> 
 </div>
   )
}

