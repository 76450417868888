import React from 'react'
import { ProfileContent } from '../ProfileContent'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DevicesIcon from '@mui/icons-material/Devices';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { NavLink } from "react-router-dom";
import GradingIcon from '@mui/icons-material/Grading';
import "./dashboard.css";


export default function Dashboard() {

    return (
        <div className='top-container'>
            <h5>Dashboard</h5>
            <hr />
            <ProfileContent />
            <div className='icon-container'  >
                <div className='dashboard-links'>
                <NavLink className="nav-link" to="/dashboard"><DashboardIcon sx={{color: 'red'}} style={{fontSize: 200}}/></NavLink>
                <h5>Dashboard</h5>
                </div>
                <div className='dashboard-links'>
                <NavLink className="nav-link" to="/clients" ><DevicesIcon sx={{color: '#3F99E1'}} style={{fontSize: 200}}/></NavLink>
                <h5>Clients</h5>
                </div>
                <div className='dashboard-links'>
                <NavLink className="nav-link" to="/templatePage"><CreateNewFolderIcon sx={{color: '#F96B13'}} style={{fontSize: 200}} /></NavLink>
                <h5>Templates</h5>
                </div>
                <div className='dashboard-links'>
                <NavLink className="nav-link" to="/review"><GradingIcon sx={{color: '#5ED3C0'}} style={{fontSize: 200}}/></NavLink>
                <h5>Review</h5>
                </div>
                <div className='dashboard-links'>
                <NavLink className="nav-link" to="/users"><GroupAddIcon sx={{color: '#9463CC'}} style={{fontSize: 200}} /></NavLink>
                <h5>Users</h5>
                </div>
            </div>
        </div>
    )

}
