import React from 'react';
import * as Diff2Html from "diff2html";


const Diff = require("diff");

const original = `# MASTER SERVICES AGREEMENT

THIS MASTER SERVICES AGREEMENT ("Agreement") between Sagiss Group, LLC
doing business as Sagiss, LLC ("Sagiss"), a Texas limited liability
company whose address is 1616 Corporate Court, Suite 140, Irving, TX
75038, and ("Client"), whose address is , is made effective as of
("Effective Date"). Sometimes Sagiss and Client are referred to as
"Party" or the "Parties".

The Parties agree as follows:

##### Disclaimers of Other Warranties

SAGISS MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, AS TO ANY SERVICE
OR PRODUCT PROVIDED HEREUNDER BESIDES AND EXCEPT AS EXPRESSLY SET FORTH
IN THIS AGREEMENT. ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, ARE HEREBY
DISCLAIMED INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY,
TITLE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.`

const changes = `# MASTER SERVICES AGREEMENT

THIS MASTER SERVICES AGREEMENT ("Agreement") between TBGL Group, LLC
doing business as TBGL, LLC ("TBGL"), a Texas limited liability
company whose address is 1616 Corporate Court, Suite 140, Irving, TX
75038, and ("Client"), whose address is , is made effective as of
("Effective Date"). Sometimes TBGL and Client are referred to as
"Party" or the "Parties".

The Parties agree as follows:

##### Disclaimers of Other Warranties

SAGISS MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, AS TO ANY SERVICE
OR PRODUCT PROVIDED HEREUNDER BESIDES AND EXCEPT AS EXPRESSLY SET FORTH
IN THIS AGREEMENT. ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, ARE HEREBY
DISCLAIMED INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY,
TITLE, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.`

export default function DiffTool() {
  const diff = Diff.createTwoFilesPatch(
    "MSA",
    "MSA-Changes",
    original, 
    changes
 
  );

  let outputHtml = Diff2Html.html(diff, {
    inputFormat: "diff",
    showFiles: false,
    matching: "lines",
    drawFileList: false,
    outputFormat: "side-by-side",
  });

  return (
    <div className="diff-tool">
      <div dangerouslySetInnerHTML={{ __html: outputHtml }} />
    </div>
  );
}
