import * as React from 'react';
import { useMotionValue, Reorder, useDragControls } from 'framer-motion';
import { useRaisedShadow } from './use-raised-shadow';
import { ReorderIcon } from './Icon';
import CloseIcon from './CloseIcon';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './styles.css';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  item: any;
  onRemove3: any;
}

export const Item = ({ item, onRemove3 }: Props) => {
  const [open, setOpen] = React.useState(false)
  const handleDeleteOpen = () => {
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();
  console.log('This is the item: ', item);

  console.log('This item from Item Comp: ', item);
  console.log('This is item id', item.props.id);

  return (
    <div>
    <Reorder.Item
      value={item}
      id={item}
      style={{ boxShadow, y }}
      dragListener={false}
      dragControls={dragControls}
    >
      {item}

      
{/*       <CloseIcon onClick={() => console.log("clicked remove")}></CloseIcon>
 */}

      <ReorderIcon dragControls={dragControls} />
      <DeleteForeverIcon onClick={() => handleDeleteOpen()} color="disabled" sx={{ fontSize: 40, left:"100.85%", bottom:175, position:"relative"}}></DeleteForeverIcon>
    </Reorder.Item>
    <Dialog
        open={open}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to remove this section?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onRemove3(item.props.id)}>Remove Section</Button>
          <Button onClick={handleDeleteClose} autoFocus>
            Keep Section
          </Button>
        </DialogActions>
      </Dialog>

            </div>
  );
};