import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { AgreementView } from '../agreement/AgreementView'



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  textTransform: 'none'
}));

export const Clients = () => {

  const [clients, setClients] = useState([])

  useEffect(() => {
    async function fetchClient() {
      const opts = {
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const response = await fetch("https://cma-goapi.scottiplaw.com/clients", opts)
      if (!response.ok) {
        console.log(response.statusText)
      }
      const clients = await response.json()
      setClients(clients)
    }
    fetchClient();
    return;
  }, []);
  console.log(clients)

  const [value, setValue] = useState('')
  const [isActive, setIsActive] = useState(false)  //use state to hide AgreementView until Client Name is clicked

  const valueHandler = (e) => {
    let name = e.target.innerText
    setValue(name)
    setIsActive(true)
    console.log(e)
  }

  const resetActiveState = () => {
    setIsActive(false)
  }

  if (isActive) {
    return (
      <div className='top-container'>
        <Button onClick={resetActiveState} variant='outlined' style={{ marginTop: 20 }}>Back</Button>
        <AgreementView value={value} />
      </div>
    )
  }
  else {

    return (
      <div className='top-container'>
        <h5>Clients</h5>
        <hr />
        <NavLink to="/CreateClient" ><Button style={{ marginBottom: 20 }} variant="contained">Create New Client</Button></NavLink>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell align="left">Client</StyledTableCell>
                <StyledTableCell align="left">Full Name</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((client) => {
                if (client.username !== 'scott-and-scott') {
                  return (
                    <StyledTableRow key={client.id}>
                      <StyledTableCell component="th" scope="client"><span>{client.id}</span></StyledTableCell>
                      <StyledTableCell align="left"><Link to={`${client.username}`} style={{ textDecoration: 'none' }} ><Button variant="text" value={value} style={{ height: 20, textTransform: 'none', justifyContent: 'left' }} onClick={valueHandler}>{client.username}</Button></Link></StyledTableCell>
                      <StyledTableCell align="left"><span>{client.full_name}</span></StyledTableCell>
                    </StyledTableRow>
                  )
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

}

