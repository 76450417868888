import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Routes, Route } from 'react-router-dom';
import { SignInPage } from "./components/SignInPage"
import Sidebar from "./components/Sidebar"
import './App.css';
import Dashboard from "./components/dashboard/Dashboard";
import { Clients } from "./components/clientsPage/Clients"
import { Review } from "./components/reviewPage/Review"
import { CreateClient } from "./components/clientsPage/CreateClient";
import { Users } from "./components/userPage/Users"
import { CreateUser } from "./components/userPage/CreateUser"
import CreateTemplate from './components/templatePage/CreateTemplate'
import { AddAgreement } from './components/agreement/AddAgreement'
import { ErrorPage } from './components/errorPage/ErrorPage'
import { Agreement } from './components/agreement/Agreement'
import { AgreementView } from "./components/agreement/AgreementView";
import EditTemplate from './components/templatePage/EditTemplate'
import { TemplatePage } from "./components/templatePage/TemplatePage";
import { Template } from "./components/templatePage/Template";

function App() {
  return (
    <>
      <SignInPage />
      <AuthenticatedTemplate>
        <Sidebar />
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/review" element={<Review />} />
          <Route path="/clients" exact={true} element={<Clients />} />
          <Route path="/clients/:clientName/agreement/:name" element={<Agreement />} />
          <Route path="clients/:clientName/" element={<AgreementView />} />
          <Route path="/createClient" element={<CreateClient />} />
          <Route path="/createUser" element={<CreateUser />} />
          <Route path="/addAgreement" element={<AddAgreement />} />
          <Route path="/createtemplate" element={<CreateTemplate />} />
          <Route path="/templatePage" element={<TemplatePage />} />
          <Route path="*" exact={true} element={<ErrorPage />} />
          <Route path="/templatePage/edit-template/:doc" element={<EditTemplate />} />
          <Route path="/clients/:org/edit-agreement/:org/:doc" element={<EditTemplate />} />
          <Route path="templatePage/template/:name" element={<Template />} />
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p style={{ textAlign: 'center', marginTop: 20 }}>You are not signed in! Please sign in.</p>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
