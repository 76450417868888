import React from 'react'
import { useParams } from 'react-router-dom'


export const Template = () => {

    let {name} = useParams()

    return (
        <div className='top-container'>
            <h5>Template detail Page</h5>
            <hr/>
            <div>{name}</div>
        </div>
    )


}

