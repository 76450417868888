import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "react-bootstrap/Button";

async function handleLogin(instance) {
    // Value.IDToken is Jwt We need to pass to the go validation middleware
    // After this IsAuth is called
    let bearer_token = await instance.loginPopup(loginRequest) // Must be await or the auth will Fail
        .then((value) => {
            console.log(value);
            return value.idToken
        })
    //TODO Remove
    //TODO comments
    console.log(bearer_token)
    var url = "https://cma-goapi.scottiplaw.com/auth";
    var bearer = 'Bearer ' + bearer_token;
    await fetch(url, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(json => {

        console.log(json)
    })
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button variant="oulined" className="ml-auto" onClick={() => handleLogin(instance)} style={{ border: 'none' }}>Sign in</Button>
    );
}