import MDEditor, { commands } from '@uiw/react-md-editor';
import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import AgrName from '../agreement/AgreementName';
import '@fontsource/roboto/700.css';
import { useNavigate } from 'react-router-dom';
//import Draggable from "react-draggable";
import { Reorder } from "framer-motion";
import { Item } from "../dragcontrol/Item"; 
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export default function AppMD() {

    const center = {
        name: 'center',
        keyCommand: 'center',
        buttonProps: {'arial-label': 'Center element'},
        icon: (   <svg width="12" height="12" viewBox="0 0 520 520">
        <FormatAlignCenterIcon/>
      </svg>),
       execute: (state, api) => {
        let modifyText = `<center>${state.selectedText}</center>`;
        if (!state.selectedText) {
          modifyText = `<center>${state.selectedText}`;
        }
        api.replaceSelection(modifyText);
      },
      }
      
      const underline = {
        name: 'underline',
        keyCommand: 'underline',
        buttonProps: {'arial-label': 'Underline element'},
        icon: (   <svg width="12" height="12" viewBox="0 0 520 520">
          <FormatUnderlinedIcon/>
      </svg>),
       execute: (state, api) => {
        let modifyText = `<u>${state.selectedText}</u>`;
        if (!state.selectedText) {
          modifyText = `<u>${state.selectedText}`;
        }
        api.replaceSelection(modifyText);
      },
      }
      
      const title2 = {
        name: 'title3',
        keyCommand: 'title3',
        render: (command, disabled, executeCommand) => {
          return (
            <button 
              aria-label="Insert title3"
              disabled={disabled}
              onClick={(evn) => {
                evn.stopPropagation();
                executeCommand(command, command.groupName)
              }}
            >
              <svg width="12" height="12" viewBox="0 0 520 520">
                <path fill="currentColor" d="M15.7083333,468 C7.03242448,468 0,462.030833 0,454.666667 L0,421.333333 C0,413.969167 7.03242448,408 15.7083333,408 L361.291667,408 C369.967576,408 377,413.969167 377,421.333333 L377,454.666667 C377,462.030833 369.967576,468 361.291667,468 L15.7083333,468 Z M21.6666667,366 C9.69989583,366 0,359.831861 0,352.222222 L0,317.777778 C0,310.168139 9.69989583,304 21.6666667,304 L498.333333,304 C510.300104,304 520,310.168139 520,317.777778 L520,352.222222 C520,359.831861 510.300104,366 498.333333,366 L21.6666667,366 Z M136.835938,64 L136.835937,126 L107.25,126 L107.25,251 L40.75,251 L40.75,126 L-5.68434189e-14,126 L-5.68434189e-14,64 L136.835938,64 Z M212,64 L212,251 L161.648438,251 L161.648438,64 L212,64 Z M378,64 L378,126 L343.25,126 L343.25,251 L281.75,251 L281.75,126 L238,126 L238,64 L378,64 Z M449.047619,189.550781 L520,189.550781 L520,251 L405,251 L405,64 L449.047619,64 L449.047619,189.550781 Z" />
              </svg>
            </button>
          )
        },
        execute: (state, api) => {
          let modifyText = `## ${state.selectedText}\n`;
          if (!state.selectedText) {
            modifyText = `## `;
          }
          api.replaceSelection(modifyText);
        },
      }
      

    console.log("Create Template Render")
    const [sections, setSection] = useState([]);
    const [spinner, setSpinner] = useState(false)
    const sectionRayRef = useRef([])
    sectionRayRef.current = sections
    let navigate = useNavigate()
    const [inputList, setInputList] = useState([<AddEditor key={0} id={0} sectionData={setSection} content={""} setSection={setSection} />]);

    console.log("this is section log Top", sections)

    const submitAll = (data) => {
        console.log("Inside SubmitAll")
        console.log("Agreement name is: ", data)
        setSpinner(true)
        postSection2Server(data)
        // navigate("/templatePage")
        //window.location.reload(false)
    }
    // TODO Move AddEditor To Separate File
    function AddEditor(props) {
        const [value, setValue] = React.useState("");
        console.log(`%c[Running AddEditor]`, 'background: lightblue; color: #444; padding: 3px; border-radius: 5px;');

        function UpdateSecBox(e) {
            console.log("This is inside Editor...Logging Event: ", e)
            // SetState for current input Value)(text box)
            setValue(e);
            //Update Section inside or OverWrite
            let copySection = [...sectionRayRef.current]
            console.log("This is CopySection", copySection)
            console.log("section REF ", sectionRayRef.current)
            //Find the element in copySection and update the content
            const index = copySection.findIndex(object => {
                return object.Editorid === props.id;
            });
            console.log(index);
            if (copySection[index]) {
                console.log("Exist")
                copySection[index].content = e;
                // copySection[index] = {
                //     Editorid: props.id,
                //     content: e
                // }
                setSection(copySection)
            } else {
                //! Remove if useeffect works
                console.log(`%c[DOES NOT EXIST]`, 'background: lightblue; color: #444; padding: 3px; border-radius: 5px;');
                console.log("Does Not Exist")
                console.log("CREATING")
                setSection([...sectionRayRef.current, {
                    Editorid: props.id,
                    content: e
                }])
            }

        }

        const showEditorVals = () => {
            console.log("Show Props", props)
        }
        useEffect(() => {
            //console.log("This is inside useEffect")
            //console.log("This is props", props.id)
            setSection([...sectionRayRef.current, {
                Editorid: props.id,
                content: "",
            }])
        }, []);

        return (
            <>
                <MDEditor 
                value={value} 
                id={inputList.length} 
                onChange={UpdateSecBox}  
                commands={[commands.bold, 
                            commands.italic, 
                            commands.strikethrough, 
                            commands.hr, 
                            title2, 
                            center, 
                            underline, 
                            commands.divider, 
                            commands.link, 
                            commands.quote, 
                            commands.code, 
                            commands.image, 
                            commands.divider, 
                            commands.unorderedListCommand, 
                            commands.orderedListCommand, 
                            commands.checkedListCommand]} 
                style={{ marginBottom: 10 , width: '100%'}} />
            </>

        )
    }
    // Post Sections to GO API...
    const postSection2Server = (agr) => {
        console.log("Inside PostSection2Server data fromAgrName", agr)
        sections.forEach(element => {

            element.agrname = agr
        });
        fetch("https://cma-goapi.scottiplaw.com/createtemplate", {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(sections)
        }).then(data => {
            if (data.status === 409) {
                console.log("The repository with the same name already exists")
            }
            if (data.status === 200){
                navigate("/templatePage")
            }
            console.log(data); // JSON data parsed by `data.json()` call
        });
    }


    const onAddBtnClick = event => {
        setInputList(inputList.concat(<AddEditor key={inputList.length} id={inputList.length} setsection={setSection} content={""} testdata={event} />));
    };

    const reOrderSet = event => {

        console.log("Reorder Set")
        console.log("New Event Set ", event)
        let copySection = [...sectionRayRef.current]
        event.forEach((item, index) => {
            // Get the id of the first index of the reordered Input List
            // oldEditorID = item.props.id
            let inputListID = item.props.id //?? {2}
            console.log("InputListItem Id:", item.props.id)
            // Find the index of the item in the Sections with Editor Id equal to the inputListID
            const TargetSectionIndex = copySection.findIndex(object => {
                return object.Editorid === inputListID;
            });
            console.log("Target Index", TargetSectionIndex)
            const toIndex = index
            const element = copySection.splice(TargetSectionIndex, 1)[0];
            console.log("Element", element)
            //element.Editorid = index
            copySection.splice(toIndex, 0, element);

            //element.Editorid = index
        })

        console.log("Final Copy Sections", copySection)
        setInputList(event)
        setSection(copySection)
    };
    const showInputListState = () => {
        console.log("Show InputList", inputList)
    }
    const showSections = () => {
        console.log("Show Sections", sections)
    }
    const remove = val => {
        console.log("Remove This", val)
        //console.log("Add Button Clicked Event2", event)
        console.log("Remove Function Show List", inputList)
        setInputList(inputList.filter(item => item.props.id !== val));
        setSection(sectionRayRef.current.filter(item => item.Editorid !== val))
    };
    return (
        <div className='top-container'>
        <h5>Template Creation</h5>
        <hr />
        {spinner ?
        <div> 
        <Box sx={{ display: 'flex' }}>
            <span style={{marginLeft: '40%',marginRight: 10, fontWeight: 'bolder', fontSize: 30}}>Publishing Document <div style={{marginTop: 10, marginLeft: '40%'}}><CircularProgress /></div></span>
        </Box>
        <div  style={{opacity: '.5', pointerEvents:'none'}}>
        <AgrName postAll={submitAll} ></AgrName>
        <Reorder.Group axis="y" as="ol" values={inputList} onReorder={reOrderSet}>

            {inputList.map((item) => (
                <Reorder.Item key={item.key} value={item}>
                    {item}
                </Reorder.Item>
            ))}
        </Reorder.Group>
        <Button variant="contained" onClick={onAddBtnClick} style={{ marginRight: 5, marginTop: 20 }} >Add Section {inputList[0].props.test}</Button>    
        </div>
        </div>
        :  
        <div>
        <AgrName postAll={submitAll} ></AgrName>
        <Reorder.Group axis="y" values={inputList} onReorder={reOrderSet} >
                {inputList.map((item) => (
                    <Item key={item.key} item={item} onRemove3={(val) => remove(val)} />
                ))}

                <Button variant="contained" onClick={onAddBtnClick} style={{ marginRight: 5, marginTop: 20 }} >Add Section</Button>
            </Reorder.Group>
        <Button variant="contained" onClick={onAddBtnClick} style={{ marginRight: 5, marginTop: 20 }} >Add Section {inputList[0].props.test}</Button>    
        </div>}
    </div>
    );

}
