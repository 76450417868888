import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { NavLink, useParams, Link } from "react-router-dom";
import { AddAgreement } from '../agreement/AddAgreement';
import ConfirmDialog from './ConfirmlDialog';
import OpenPrConfirmDialog from './OpenPrConfirmDialog';
import LogoUpload from '../clientsPage/LogoUpload';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const AgreementView = (props) => {
    console.log("Running Agreement View Func")
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [confirmOpenPublish, setConfirmOpenPublish] = useState(false)
    const [reponame, setRepoName] = useState('')
    const [showAgreementToAdd, setshowAgreementToAdd] = useState(false)
    const [repos, setRepos] = useState([])
    const [showUploadLogoButton, setShowUploadLogoButton] = useState(false)
    // const [draftDocId, setDraftDocId] = useState([])
    const [masterDocId, setMasterDocId] = useState([])



    const ApproveAgreement = (e) => {
        setConfirmOpenPublish(true)
        console.log('approved')
        console.log(e.target.value)
        setRepoName(e.target.value)
    }

    const ConfirmPRDialog = (e) => {
        setConfirmOpen(true)
        console.log('Open PR confirmed')
        console.log(e.target.value)
        setRepoName(e.target.value)
    }



    let { clientName } = useParams()
    console.log(clientName)
    let results = useParams()
    console.log("Prams", results)


    //get all repos
    useEffect(() => {
        console.log("Running useEffect fetchRepos")
        async function fetchRepos() {
            const response = await fetch("https://cma-goapi.scottiplaw.com/listRepos")
            if (!response.ok) {
                console.log(response.statusText)
            }
            const repos = await response.json()
            console.log(repos)
            setRepos(repos)
        }
        fetchRepos();
        console.log("fetchRepos", repos)

        return;
    }, []);

    //filter Repos based on Owner's Login name
    let repoArr = []
    repos.forEach(repos => {
        if (repos.owner.login === clientName) {
            repoArr.push(repos)
            console.log(repoArr)
        }
    })
    console.log(repoArr)



    //Get data from Python api/media
    const [data_, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            try {
                const settings = { headers: { TENANT: `${clientName}` } };
                const response = await fetch(
                    "https://cma-python-api.scottiplaw.com/api/media/latest",
                    settings
                );
                const data_ = await response.json();
                setData(data_);
            } catch (err) {
                console.log(err);
            }
        };
        getData();
    }, []);

    console.log(data_)

    let master = []
    let draft = []
    data_.forEach((item) => {
        if (item.document_status === "True") {
            master.push(item)
        }
        else {
            draft.push(item)
        }
    })
    console.log(master)
    console.log(draft)



    let fixed_client_name = clientName.replace(/-/g, ' ');


    const AgreementToAddList = () => {
        return (
            <div className='top-container' style={{ marginTop: 20, marginBottom: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 style={{ textTransform: 'capitalize' }}>{fixed_client_name}</h4>
                    <Button variant="outlined" style={{ marginTop: 10, marginBottom: 5, marginLeft: 20 }} onClick={uploadLogoButton} >Upload Logo</Button>
                </div>
                <TableContainer component={Paper}>
                    <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Agreement Name (Live)</StyledTableCell>
                                <StyledTableCell>Live Id</StyledTableCell>
                                <StyledTableCell >Status</StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>Draft Id</StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(repoArr).map(([index, { id, name, open_pr_counter, draftid, masterid }]) => (

                                <StyledTableRow key={id}>

                                    <StyledTableCell>
                                        {master.forEach((item) => {
                                            if (item.file_name === name && item.document_status === "True") {
                                                masterid = item.id
                                            }
                                        })}
                                        {draft.forEach((item) => {
                                            if (item.file_name === name && item.document_status === "False") {
                                                draftid = item.id
                                            }
                                        })
                                        }
                                        <a target="blank" href={`https://cma-client-portal.scottiplaw.com/${clientName}/${masterid}`} style={{ textDecoration: 'none', textTransform: 'capitalize' }}>{name}</a>  {/* id Needs to be dynamic*/}
                                    </StyledTableCell>
                                    <StyledTableCell><span style={{ marginLeft: 10 }}>{masterid}</span></StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <div>
                                            <span>{
                                                open_pr_counter ? <span style={{ color: 'red' }}>Draft</span> :
                                                    masterid === undefined && draftid === undefined ? <span style={{ marginLeft: 5, marginRight: 15 }}>--</span> :
                                                        masterid !== undefined ? <span style={{ color: '#20C997', marginRight: 3, paddingRight: 3 }}>Live</span> :
                                                            ""
                                            }
                                            </span>
                                            {
                                                !open_pr_counter ? <Button variant="text" disabled={true} style={{ marginLeft: 25, height: 15, textTransform: 'none' }} onClick={ApproveAgreement} value={name}>Publish</Button> :
                                                    open_pr_counter && draftid !== undefined && draftid < masterid ? <Button variant="text" disabled={true} style={{ marginLeft: 25, height: 15, textTransform: 'none' }} onClick={ApproveAgreement} value={name}>Publish</Button> :
                                                        open_pr_counter && draftid !== undefined && draftid > masterid ? <Button variant="text" style={{ marginLeft: 25, height: 15, textTransform: 'none' }} onClick={ApproveAgreement} value={name}>Publish</Button> :
                                                            open_pr_counter && draftid !== undefined ? <Button variant="text" style={{ marginLeft: 25, height: 15, textTransform: 'none' }} onClick={ApproveAgreement} value={name}>Publish</Button> :
                                                                open_pr_counter && draftid === undefined ? <Button variant="text" disabled={true} style={{ marginLeft: 25, height: 15, textTransform: 'none' }} onClick={ApproveAgreement} value={name}>Publish</Button> :
                                                                    !open_pr_counter && draftid !== undefined ? <Button variant="text" disabled={true} style={{ marginLeft: 25, height: 15, textTransform: 'none' }} onClick={ApproveAgreement} value={name}>Publish</Button> :
                                                                        ""
                                            }
                                            <ConfirmDialog
                                                title={`Click 'Yes' Publish ${clientName}'s ${reponame} agreement? This will make the agreement live.`}
                                                open={confirmOpenPublish}
                                                setOpen={setConfirmOpenPublish}
                                                onConfirm={approvePost}
                                                owner={clientName} //need for merge of pull request
                                                repo={reponame}  //need for  merge of pull request
                                            >
                                            </ConfirmDialog>

                                            <OpenPrConfirmDialog
                                                title={`Click 'Yes' to start editing ${clientName}'s ${reponame} agreement?`}
                                                open={confirmOpen}
                                                setOpen={setConfirmOpen}
                                                onConfirm={openPullRequest}
                                                owner={clientName} //need for pull request
                                                repo={reponame}  //need for pull request 
                                            >
                                            </OpenPrConfirmDialog>
                                        </div>

                                    </StyledTableCell>
                                    <StyledTableCell>


                                        {masterid === undefined && draftid === undefined ? <span style={{ marginLeft: 30 }}></span> :
                                            <a target="blank" href={`https://cma-client-portal.scottiplaw.com/${clientName}/${draftid}`} style={{ textDecoration: 'none' }}>Draft Link</a>}
                                    </StyledTableCell>
                                    <StyledTableCell><span style={{ marginLeft: 15 }}>{draftid}</span></StyledTableCell>
                                    <StyledTableCell >
                                        {!open_pr_counter ? 
                                        <Button 
                                            variant="text" 
                                            style={{ height: 20, textTransform: 'none', justifyContent: 'center', float: 'right' }}  
                                            onClick={ConfirmPRDialog} value={name}>Edit
                                        </Button>
                                        :
                                        <Badge overlap="circular"  variant="dot" color="secondary"  style={{float: 'right'}}> 
                                        <Button 
                                            variant="text" 
                                            style={{height: 20, textTransform: 'none', justifyContent: 'center'}} 
                                            component={NavLink} to={`edit-agreement/${clientName}/${name}`}>Edit
                                        </Button>
                                        </Badge>
                                        }
                                    </StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }



    //Create Pull Request
    const openPullRequest = (OrgName, reponame) => {
        console.log('Open pull request confirmed')
        console.log('Edit button', OrgName)
        console.log('Edit button', reponame)
        let url = `https://cma-goapi.scottiplaw.com/createpr/${OrgName}/${reponame}`;
        async function createPrApi() {

            const response = await fetch(url, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (!response.ok) {
                console.log(response.statusText)
            }
            const draftDocId = await response.json()
            // setDraftDocId(draftDocId)
            // console.log(draftDocId)
        }
        createPrApi()
    }

    // Merge Draft with Master

    const approvePost = (OrgName, reponame) => {
        console.log('Running Merge Func After Approve')
        console.log(OrgName, reponame)
        let url = `https://cma-goapi.scottiplaw.com/mergepr/${OrgName}/${reponame}`;
        async function mergeDocApi() {
            const response = await fetch(url, {
                method: 'GET',
                withCredentials: true,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.ok) {
                window.location.reload()
            }
            if (!response.ok) {
                alert(response.statusText)
            }
            const masterDocId = await response.json()
            console.log(masterDocId)
            setMasterDocId(masterDocId)
            postAgreementToBlob(masterDocId)

        }
        mergeDocApi()
        // setTimeout(function(){
        //     window.location.reload()
        //  }, 2000);
    }
    console.log('Master Doc ID', masterDocId)
    const postAgreementToBlob = (masterDocId) => {
        try {
            fetch('https://cma-python-api.scottiplaw.com/api/events',
                {
                    method: 'POST',
                    body: JSON.stringify({ "doc_id": masterDocId }),
                    headers: { 'Content-Type': 'application/json' }
                }
            )
        } catch (err) {
            console.log(err)
        }
    }


    const listAgreementsToAdd = () => {
        setshowAgreementToAdd(true)
    }

    const handleCancel = () => {
        setshowAgreementToAdd(false)
    }


    const uploadLogoButton = () => {
        setShowUploadLogoButton(true)
    }



    if (showAgreementToAdd) {
        return (
            <div className='top-container' >
                <AgreementToAddList />
                <AddAgreement name={clientName} repos={repoArr} />
                <Button variant="outlined" style={{ marginBottom: 50, marginTop: -167, marginLeft: 80, height: 31 }} onClick={handleCancel}>Cancel</Button>
            </div>
        )
    }
    if (showUploadLogoButton) {
        return (
            <div className='top-container'>
                <LogoUpload value={clientName} />
            </div>
        )
    }
    else {
        return (
            <div className='top-container'>
                <AgreementToAddList />
                <Button variant="contained" style={{ marginTop: 10, marginBottom: 50 }} onClick={listAgreementsToAdd}>Add Agreement</Button>
            </div>
        )
    }


}
