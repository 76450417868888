import MDEditor, { commands } from '@uiw/react-md-editor';
import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import '@fontsource/roboto/700.css';
import { useParams } from 'react-router-dom'
import FormDialog from './CommentDialog'
import EditIcon from '@mui/icons-material/Edit';
import { Reorder } from "framer-motion";
import { Item } from "../dragcontrol/Item";
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
//import "./styles.css";
export default function EditTemplate() {

    const center = {
        name: 'center',
        keyCommand: 'center',
        buttonProps: {'arial-label': 'Center element'},
        icon: (   <svg width="12" height="12" viewBox="0 0 520 520">
        <FormatAlignCenterIcon/>
      </svg>),
       execute: (state, api) => {
        let modifyText = `<center>${state.selectedText}</center>`;
        if (!state.selectedText) {
          modifyText = `<center>${state.selectedText}`;
        }
        api.replaceSelection(modifyText);
      },
      }
      
      const underline = {
        name: 'underline',
        keyCommand: 'underline',
        buttonProps: {'arial-label': 'Underline element'},
        icon: (   <svg width="12" height="12" viewBox="0 0 520 520">
          <FormatUnderlinedIcon/>
      </svg>),
       execute: (state, api) => {
        let modifyText = `<u>${state.selectedText}</u>`;
        if (!state.selectedText) {
          modifyText = `<u>${state.selectedText}`;
        }
        api.replaceSelection(modifyText);
      },
      }
      
      const title2 = {
        name: 'title3',
        keyCommand: 'title3',
        render: (command, disabled, executeCommand) => {
          return (
            <button 
              aria-label="Insert title3"
              disabled={disabled}
              onClick={(evn) => {
                evn.stopPropagation();
                executeCommand(command, command.groupName)
              }}
            >
              <svg width="12" height="12" viewBox="0 0 520 520">
                <path fill="currentColor" d="M15.7083333,468 C7.03242448,468 0,462.030833 0,454.666667 L0,421.333333 C0,413.969167 7.03242448,408 15.7083333,408 L361.291667,408 C369.967576,408 377,413.969167 377,421.333333 L377,454.666667 C377,462.030833 369.967576,468 361.291667,468 L15.7083333,468 Z M21.6666667,366 C9.69989583,366 0,359.831861 0,352.222222 L0,317.777778 C0,310.168139 9.69989583,304 21.6666667,304 L498.333333,304 C510.300104,304 520,310.168139 520,317.777778 L520,352.222222 C520,359.831861 510.300104,366 498.333333,366 L21.6666667,366 Z M136.835938,64 L136.835937,126 L107.25,126 L107.25,251 L40.75,251 L40.75,126 L-5.68434189e-14,126 L-5.68434189e-14,64 L136.835938,64 Z M212,64 L212,251 L161.648438,251 L161.648438,64 L212,64 Z M378,64 L378,126 L343.25,126 L343.25,251 L281.75,251 L281.75,126 L238,126 L238,64 L378,64 Z M449.047619,189.550781 L520,189.550781 L520,251 L405,251 L405,64 L449.047619,64 L449.047619,189.550781 Z" />
              </svg>
            </button>
          )
        },
        execute: (state, api) => {
          let modifyText = `## ${state.selectedText}\n`;
          if (!state.selectedText) {
            modifyText = `## `;
          }
          api.replaceSelection(modifyText);
        },
      }


    //TODO Change details
    console.log("Main Re-Render")
    const ParamsResults = useParams();
    console.log("Pram Results", ParamsResults)


    const baseUrl = ParamsResults["*"].replace(ParamsResults.doc, '');
    // Determine rather to get a master or draft Branch from API
    let branch = (baseUrl === "templatePage/edit-template/") ? "master" : "draft"

    // This will display the correct Editor name based on url parameters
    const path = window.location.pathname
    console.log(path)
    let editorName = ''
    let strSplit = path.split('/')
    strSplit.forEach(e => {
        if (e === 'edit-template') {
            editorName = 'Template'
        }
        if (e === 'edit-agreement') {
            editorName = 'Agreement'
        }
        console.log(editorName)
    })

    if (!ParamsResults.org) {
        console.log("True")
        //?? Replace Static??
        ParamsResults.org = process.env.REACT_APP_ORGNAME
    }

    
    const { doc, org } = ParamsResults

    // Static ORGNAME For admin portal 
    console.log("ENV ORGNAME: ", process.env.REACT_APP_ORGNAME)


    const [sections, setSection] = useState([]);
    const sectionRayRef = useRef([])
    sectionRayRef.current = sections

    const [inputList, setInputList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [docID, setdocID] = useState();
    console.log("this is section log Top", sections)
    // Adds Section Content(textBox input value) to state array
    function getTemplate() {
        console.log("Running GetTemplate")
        console.log(org, doc)
        // Get Contents of A repo
        // API calls must be in use effect hook
        var url = `https://cma-goapi.scottiplaw.com/editdocument/${org}/${doc}/${branch}`;
        fetch(url, {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json()).then(goapiDocData => {

            console.log("Response when calling editdocument", goapiDocData)
            if (goapiDocData === "Error Retriving Template") {
                console.log("Error when calling editdocument")
                return
            }
            setdocID(goapiDocData.docid)
            addSecs3(goapiDocData.sections)
        })

    }

    // function callbackSetSection(dataFromEdit) {
    //     console.log("Section inside passData", sections)
    //     console.log(dataFromEdit)
    //     //Update Section inside of OverWrite
    //     let copySection = [...sectionRayRef.current]
    //     console.log("This is CopySection", copySection)
    //     if (copySection[dataFromEdit.id]) {
    //         console.log("Exist")
    //         copySection[dataFromEdit.id] = {
    //             Editorid: dataFromEdit.id,
    //             content: dataFromEdit.newvalue
    //         }
    //         setSection(copySection)
    //     } else {
    //         console.log("Does Not Running SetSection inside callbackSetSection")
    //         setSection([...sectionRayRef.current, {
    //             Editorid: dataFromEdit.id,
    //             content: dataFromEdit.newvalue
    //         }])
    //     }
    // }

    const submitAll = (data) => {
        setOpen(true)
    }
    // TODO Move AddEditor To Separate File
    function AddEditor(props) {
        //UPdate
        const [value, setValue] = React.useState(props.content);

        function UpdateSecBox(e) {
            console.log("This is inside Editor...Logging Event: ", e)
            console.log("Props-sha", props.sha)
            // SetState for current input Value)(text box)
            setValue(e);
            let copySection = [...sectionRayRef.current]
            const index = copySection.findIndex(object => {
                return object.Editorid === props.id;
            });
            console.log(index);
            if (copySection[index]) {
                //! Remove

                console.log("Exist")
                //console.log("",   copySection[index])
                // copySection[index] = {
                //     Editorid: props.id,
                //     content: e,
                //     sha: copySection[index].sha,
                //     secFname: props.secFname,
                //     sqlID: props.sqlID
                // }
                copySection[index].content = e
                setSection(copySection)
                //console.log("sections after clone change", copySection)


            } else {
                console.log(`%c[DOES NOT EXIST]`, 'background: lightblue; color: #444; padding: 3px; border-radius: 5px;');

                console.log("DOES NOT EXIST")
                setSection([...sectionRayRef.current, {
                    Editorid: props.id,
                    content: e,
                    sha: props.sha,
                    secFname: props.secFname,
                    sqlID: props.sqlID
                }])
            }
            //console.log("Section State after updatesec func", sections)
            console.log("Ref Section State after updatesec func", sectionRayRef.current)
            console.log("Copy Section", copySection)
        }
        console.log("Under udateSecBox")
        return (
            <>
                <MDEditor 
                value={value} 
                id={inputList.length} 
                onChange={UpdateSecBox} 
                style={{ marginBottom: 10, width: '100%' }} 
                commands={[commands.bold, 
                    commands.italic, 
                    commands.strikethrough, 
                    commands.hr, 
                    title2, 
                    center, 
                    underline, 
                    commands.divider, 
                    commands.link, 
                    commands.quote, 
                    commands.code, 
                    commands.image, 
                    commands.divider, 
                    commands.unorderedListCommand, 
                    commands.orderedListCommand, 
                    commands.checkedListCommand]}  />
            </>

        )
    }
    // Post Sections to GO API...
    const postSection2Server = (comment) => {
        console.log("Running PostSection func...Current Sections", sections)
        console.log("Copyof Section Ref", sectionRayRef.current)
        sections.forEach(element => {

            //element.agrname = agr
            element.comment = comment
        });
        //! FIX

        console.log("testing Vals")
        console.log(doc)
        console.log(org)
        console.log(docID)
        fetch(`https://cma-goapi.scottiplaw.com/updatetemplate`, {
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                sectiondata: sections,
                info: {
                    docid: docID,
                    docname: doc,
                    orgname: org,
                    branch: branch
                }
            })
        }).then(response => {
            if (response.status === 202) {
                console.log("Success")
                return response.json()
            }
            else {
                console.log("Error")
                return "Error"
            }
        }).then(data => {
            if (data !== "Error") {
                console.log("data from updatetemplate Response ", data)
                let docId = data.data.docid
                // console.log(data.data.docid)
                //TODO if response is successfully Call python script data.docid
                console.log(docId)
                postAgreementToBlob(docId)
                return data.data

            }

            //! test reload bug where nothing happens...await issues ?
            console.log("REMOVED RELOAD FOR TESTING BUG")
        }).then((data) => {
            console.log("data", data)
            if (data.orgname === "scott-and-scott") {
                window.location.href="/templatePage"
            } else {
                window.location.href=`/clients/${data.orgname}`
            }}
            )

        const postAgreementToBlob = (docId) => {
            console.log(docId)
            try {
                fetch('https://cma-python-api.scottiplaw.com/api/events',
                    {
                        method: 'POST',
                        body: JSON.stringify({ "doc_id": docId }),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
            } catch (err) {
                console.log(err)
            }
        }


    }

    const addSecs3 = (docSections) => {
        console.log("Running addSections2")
        let ray = [];
        for (const section of docSections) {
            let dynamicComponents = <AddEditor key={section.seq} id={section.seq} setsection={setSection} content={section.content} sha={section.sha} secFname={section.name} sqlID={section.sqlid} />
            setInputList(currentInputs => [...currentInputs, dynamicComponents])

            console.log("Current File: ", section)

            ray.push({
                Editorid: section.seq,
                content: section.content,
                sha: section.sha,
                secFname: section.name,
                sqlID: section.sqlid
            })
        };
        setSection(ray)
        console.log("Done running AddSection")
    }
    useEffect(() => {
        getTemplate(doc);
    }, []);
    const reOrderSet = event => {

        console.log("Reorder Set")
        console.log(event)
        let copySection = [...sectionRayRef.current]
        console.log("BEFORE CopySection", copySection)
        event.forEach((item, index) => {
            // Get the id of the first index of the reordered Input List

            let inputListID = item.props.id //?? {2}
            console.log("InputListItem Id:", item.props.id)
            // Find the index of the item in the Sections with Editor Id equal to the inputListID
            const TargetSectionIndex = copySection.findIndex(object => {
                return object.Editorid === inputListID;
            });
            console.log("Target Index", TargetSectionIndex)
            const toIndex = index
            const element = copySection.splice(TargetSectionIndex, 1)[0];
            console.log("Element", element)

            copySection.splice(toIndex, 0, element);

        })
        //Reset Index
        // copySection.forEach((item, index) => {

        //     item.Editorid = index
        // })

        //console.log("Final Copy Sections", copySection)

        setInputList(event)
        setSection(copySection)
    };
    const redirectTest = () => {
        //window.location.href="/templatePage"
    }
    const showInputListState = () => {
        console.log("Show InputList", inputList)
    }
    const showSections = () => {
        console.log("Show Sections", sections)
    }
    const onAddBtnClick = event => {
        console.log("Add Button Clicked Event", event)
        setInputList(inputList.concat(<AddEditor key={inputList.length} id={inputList.length} setsection={setSection} content={""} testdata={event} />));
        setSection([...sectionRayRef.current, {
            Editorid: inputList.length,
            content: "",
            new: true
        }])
    };
    
    const remove = val => {
        console.log("Remove This", val)
        //console.log("Add Button Clicked Event2", event)
        console.log("Remove Function Show List", inputList)
        setInputList(inputList.filter(item => item.props.id !== val));
        setSection(sectionRayRef.current.filter(item => item.Editorid !== val))
    };
    const remove2 = (box) => {
        console.log("Sections before remove", sections)
        console.log("Remove Box2", box)
        console.log("InputList", inputList)
        //console.log("Remove Box", box.props.id)
        // inputList.forEach((item, index) => {
        //     console.log(item)
        // })
        // let results = inputList.map(item => {
        //     return console.log(item)

        // })
        // inputList.forEach((item, index) => {
        //     console.log(item)
        // })
        //console.log(results)
        // let results = inputList.filter(item => {

        //     console.log("Item", item.id)

        //     return item
        // })

        // console.log("Results2", results)
        //setInputList(inputList.filter(item => item.props.id !== box));


    }

    return (
        <div className='top-container'>
            <h5>{editorName} Editor</h5>
            <hr />
            <h5><EditIcon style={{ marginRight: 10 }} />{ParamsResults.org}/{ParamsResults.doc}</h5>
            <FormDialog open={open} setOpen={setOpen} post={postSection2Server} doc={doc} />
            <Button variant="contained" onClick={(event) => [submitAll(), redirectTest()]} style={{ marginRight: 5, marginTop: 10, marginBottom: 20 }}>Save Change</Button>
            <Reorder.Group axis="y" values={inputList} onReorder={reOrderSet} >
                {inputList.map((item) => (
                    <Item key={item.key} item={item} onRemove3={(val) => remove(val)} />
                ))}

                <Button variant="contained" onClick={onAddBtnClick} style={{ marginRight: 5, marginTop: 20 }} >Add Section</Button>
            </Reorder.Group>
        </div>
    );
}