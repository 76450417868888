import React, { useState } from "react";
import { useNavigate } from "react-router";
import Button from '@mui/material/Button';

export const CreateClient = () => {

  const [form, setForm] = useState({
    username: "",
    full_name: "",
    location: "",
    visibility: "",
    repo_admin_change_team_access: "",
    website: "",

  });
  const navigate = useNavigate();

  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  // This function will handle the submission.
  async function onSubmit(e) {
    e.preventDefault();

    // When a post request is sent to the create url, we'll add a new Organization to Gitea.
    const newClient = { ...form };

    await fetch("https://cma-goapi.scottiplaw.com/createClient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newClient),
    })
      .catch(error => {
        window.alert(error);
        return;
      });

    setForm({ username: "", full_name: "", location: "", visibility: "public", repo_admin_change_team_access: "", website: "" });
    console.log(form)



    navigate("/clients");


  }

  // This following section will display the form that takes the input from the user.
  return (
    <div className="top-container">

      <form onSubmit={onSubmit} method="POST" action="/createClient">

        <h4>Create New Client</h4>
        <hr />
        <div className="form-group">
          <label htmlFor="username">Business Name</label>
          <input                                              //TODO need form validation to follow naming convention - no spaces
            type="text"
            required
            className="form-control"
            id="username"
            value={form.username}
            placeholder="Business name"
            onChange={(e) => updateForm({ username: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="full_name">Referred to as</label>
          <input
            type="text"
            required
            className="form-control"
            id="full-name"
            value={form.full_name}
            placeholder="Referred to as"
            onChange={(e) => updateForm({ full_name: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="location">Address</label>
          <input
            type="text"
            required
            className="form-control"
            id="location"
            placeholder="Address"
            value={form.location}
            onChange={(e) => updateForm({ location: e.target.value })}
          />
        </div>
        <div className="form-group">
          <Button variant="contained" style={{ marginTop: 40, marginBottom: 40 }}><input
            type="submit"
            value="CREATE CLIENT"
            className="btn"
            style={{ color: "white", height: 40 }}
          />
          </Button>
        </div>
      </form>
    </div>
  );
}
