import React from 'react'
import DiffTool from '../diffTool/DiffTool'



export const Review = () => {
    
    return(
        <div className='top-container'>
        <h5>Review Page</h5>
        <hr />
            <DiffTool />
        </div>
    )

}
