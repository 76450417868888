import React from "react";
import Avatar from '@mui/material/Avatar';
import { useMsal } from "@azure/msal-react";


export const ProfileData = (props) => {
    
    const { accounts} = useMsal();
    
    const name = accounts[0] && accounts[0].name;
    const username = accounts[0] && accounts[0].username;

    
    return (
        <div className="user-card">
            <Avatar></Avatar>
            <br/>
            <h5 className="card-title" >{name}</h5>
            <p className="card-username">{username}</p>   
        </div>
    );
};
