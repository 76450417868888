import React, { useState } from "react";
import { useNavigate } from "react-router";
import Button from '@mui/material/Button';


export const CreateUser = () => {


  const [form, setForm] = useState({
    email: "",
    password: "",
    username: "",
    full_name: "",

  });
  const navigate = useNavigate();

  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  // This function will handle the submission.
  async function onSubmit(e) {
    e.preventDefault();

    // When a post request is sent to the create url, we'll add a new client to Gitea.
    const newClient = { ...form };

    await fetch("https://cma-goapi.scottiplaw.com/createUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newClient),
    })
      .catch(error => {
        window.alert(error);
        return;
      });

    setForm({ email: "", password: "", username: "", full_name:"" });
    console.log(form)

    navigate("/users");
  }

  // This following section will display the form that takes the input from the user.
  return (
    <div className="top-container">

      <form onSubmit={onSubmit} method="POST" action="/createUser">

        <h5>Create New User</h5>
        <hr />
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            value={form.username}
            placeholder="Username"
            onChange={(e) => updateForm({ username: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="full_name">Full Name</label>
          <input
            type="text"
            className="form-control"
            id="full_name"
            value={form.full_name}
            placeholder="full_name"
            onChange={(e) => updateForm({ full_name: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Email"
            value={form.email}
            onChange={(e) => updateForm({ email: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Password"
            value={form.password}
            onChange={(e) => updateForm({ password: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <Button variant="contained" style={{ marginTop: 40, marginBottom: 40 }}><input
            type="submit"
            value="CREATE USER"
            className="btn"
            style={{ color: "white", height: 40 }}
          />
          </Button>
        </div>
      </form>
    </div>
  );
}
