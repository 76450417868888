import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

export default function FormDialog(props) {

    console.log("Running Dialog ")
    //const [open, setOpen] = React.useState(false);
    const [comment, setComment] = useState("")
    const [commentError, setCommentError] = useState(false)
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    //create a navigation path
    const path = window.location.pathname
    let navigatePath = ''
    let strSplit = path.split('/')
    if (strSplit[3] === 'edit-agreement') {
        navigatePath = `/clients/${strSplit[2]}`
    }
    else {
        navigatePath = '/templatePage'
    }

    const handleClose = (event, reason) => {
        if (reason === "backdropClick") {
            console.log("A comment is required")
            setCommentError(true)
            return
        }
        props.setOpen(false);
    };


    let navigate = useNavigate()
    const handleSubmit = () => {
        if (comment === "") return setCommentError(true)
        //return
        console.log(comment)
        props.post(comment)
        setCommentError(false)
        props.setOpen(false);
        // navigate(navigatePath)
        
        
        //! test reload bug where nothing happens...await issues ?
        //window.location.reload(false)
    };


    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} fullWidth={true} >
                <DialogTitle>Edit Template Comment</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter a comment for your edits
                    </DialogContentText>
                    <TextField
                        error={commentError}
                        autoFocus
                        margin="dense"
                        id="comment"
                        label="Insert Comment"
                        type="text"
                        fullWidth={true}
                        variant="standard"
                        multiline={true}
                        onChange={(e) => setComment(e.target.value)}
                        size="medium"
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit Comment</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
