import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';



export default function AgrName(props) {

    const [value, setValue] = useState('');

    function postData() {
        console.log("Running PostaData inside AgrName")
        //Call Back..Pass name to Parent
        props.postAll(value)
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div style={{ marginLeft: -7 }}>
                <TextField
                    required
                    id="outlined-required"
                    label="Agreement Name Required"
                    //defaultValue="Agreement Name"
                    value={value}
                    onChange={(e) => setValue(e.target.value)
                    }
                />
            </div>
            <Button variant="contained" onClick={postData} style={{ marginRight: 5, marginBottom: 20, marginTop: 10, float: 'center' }} >Publish Template</Button>
        </Box>
    )
}


