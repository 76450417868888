import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useState, useEffect } from 'react'
import FormGroup from '@mui/material/FormGroup';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export const AddAgreement = (props) => {
  let organizationName = (props.name)
  console.log(organizationName)
  let reposList = props.repos //repos from AgreementView AddAgreement props, list agreements already in Organization's repo
  console.log(reposList)

  //create array of Agreement names from props
  let repoListArr = []
  reposList.forEach((item) => {
    repoListArr.push(item.name)
  })
  console.log(repoListArr)

  const [agreements, setAgreements] = useState([])

  useEffect(() => {
    async function fetchClient() {
      const response = await fetch("https://cma-goapi.scottiplaw.com/GetAgreements")
      if (!response.ok) {
        console.log(response.statusText)
      }
      const agreements = await response.json()

      setAgreements(agreements)
    }
    fetchClient();
    return;
  }, []);
  console.log("agreements from goapi/GETagreements", agreements)

  //create array of Agreement names from api call
  let agreementArr = []
  agreements.forEach((item) => {
    agreementArr.push(item.name)
  })
  console.log(agreementArr)

  let difference = agreementArr.filter(x => !repoListArr.includes(x))   //Find the difference and only render what the Org does not have.
  console.log(difference)


  const [agreementsToAdd, setAgreementsToAdd] = useState([])  //create an array of agreements based on checked boxes
  const handleCheckBox = (event) => {
    let checkedAgreements = [...agreementsToAdd]
    if (event.target.checked) {
      checkedAgreements = [...agreementsToAdd, event.target.value]
    } else {
      checkedAgreements.splice(agreementsToAdd.indexOf(event.target.value), 1)
    }
    setAgreementsToAdd(checkedAgreements)
    console.log(checkedAgreements)

  }

  async function addAgreement(e) {  //handle the submission
    e.preventDefault();

    let obj = { organization: organizationName, repo: [...agreementsToAdd] }
    console.log(obj)

    await fetch("https://cma-goapi.scottiplaw.com/AddAgreement", {
      method: "POST",
      withCredentials: true,
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .catch(error => {
        window.alert(error);
        return;
      });

    if (agreementsToAdd.length === 0) {
      alert("Please select Agreements to Add")
    } else {
      alert(`${agreementsToAdd} added to ${organizationName}`)
      window.location.reload(false)
    }
    console.log(agreementsToAdd)

  }
  console.log(agreementsToAdd)



  return (
    <div className='top-container'>
      <Card variant="outlined">
        <CardContent >
          <Typography sx={{ fontSize: 15 }} color="text.secondary" gutterBottom>Select Agreements to add</Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {difference.map((item) => (
              <div>
                <FormGroup key={item.index} >
                  <FormControlLabel label={<Typography sx={{ fontSize: 15 }}>{item}</Typography>} control={<Checkbox size="small" value={item} onChange={handleCheckBox} />} />
                </FormGroup>
              </div>
            ))}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" variant="outlined" type="submit" onClick={addAgreement}>Add</Button>
        </CardActions>
      </Card>
    </div>
  )
}
