import React, {useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { NavLink} from "react-router-dom";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  
export const TemplatePage = () => {
    
//get all repos
const [repos, setRepos] = useState([])

useEffect(() => {
    async function fetchRepos() {
        const response =  await fetch("https://cma-goapi.scottiplaw.com/listRepos")
        if (!response.ok) {
            console.log(response.statusText)
        }
        const repos = await response.json()
        setRepos(repos)
    }
    fetchRepos();
    return;
}, []);
console.log(repos)

//Create array of scott-and-scott Templates
let repoArr = []
repos.forEach(repos => {
    if(repos.owner.login === 'scott-and-scott'){
        repoArr.push(repos)
        console.log(repoArr)
    }
})

function createdTimeConvert(create_at) {
    var ct = new Date(create_at);
    return ct.toLocaleString("en-Us", {month: "long", day: "2-digit", year: "numeric", hour: "numeric", minute:"numeric"})
}

function updatedTimeCoverter(updated_at) {
    var ut = new Date(updated_at);
    return ut.toLocaleString("en-Us", {month: "long", day: "2-digit", year: "numeric", hour: "numeric", minute:"numeric"})
}

    return (
        <div className='top-container'>
        <h5>Template</h5> 
        <hr/>
        <NavLink to="/createtemplate" ><Button style={{ marginBottom: 20 }} variant="contained">Create New Template</Button></NavLink>
        <TableContainer component={Paper}>
            <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Template Name</StyledTableCell>
                        <StyledTableCell>Created</StyledTableCell>
                        <StyledTableCell>Last Updated</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {Object.entries(repoArr).map(([id, {name, created_at, updated_at}]) => (
                    <StyledTableRow key={id}>
                        <StyledTableCell component="th" scope="row"><NavLink to={`template/${name}`} style={{textDecoration: 'none'}}><Button variant="text" style={{height: 20, textTransform: 'none', justifyContent: 'left'}}>{name}</Button></NavLink></StyledTableCell>
                        <StyledTableCell align="left"><span>{createdTimeConvert(created_at)}</span></StyledTableCell>
                        <StyledTableCell align="left"><span>{updatedTimeCoverter(updated_at)}</span></StyledTableCell>
                        <StyledTableCell ><Button variant="contained" style={{height: 20, textTransform: 'none', justifyContent: 'center', float: 'right'}} component={NavLink} to={`edit-template/${name}`}>Edit</Button></StyledTableCell>
                    </StyledTableRow>  
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>        

    )
}
