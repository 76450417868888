import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export const Users = () => {

  const [users, setUsers] = useState([])

  useEffect(() => {
    async function fetchClient() {
      const opts = {
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const response = await fetch("https://cma-goapi.scottiplaw.com/users", opts)
      if (!response.ok) {
        console.log(response.statusText)
      }
      const users = await response.json()
      setUsers(users)
    }
    fetchClient();
    return;
  }, []);
  console.log(users)


  return (

    <div className='top-container'>
      <h5>Users Page</h5>
      <hr />
      <TableContainer component={Paper}>
        <Table size="small" sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="left">Username</StyledTableCell>
              <StyledTableCell align="left">Full Name</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Admin</StyledTableCell>
              <StyledTableCell align="left">Active</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow key={user.id}>
                <StyledTableCell component="th" scope="user"><span>{user.id}</span></StyledTableCell>
                <StyledTableCell align="left"><NavLink style={{ textDecoration: 'none' }} to="/usersView"><Button variant="text" style={{ height: 20, textTransform: 'none', justifyContent: 'left' }} >{user.login}</Button></NavLink></StyledTableCell>
                <StyledTableCell align="left"><span>{user.full_name}</span></StyledTableCell>
                <StyledTableCell align="left"><span>{user.email}</span></StyledTableCell>
                <StyledTableCell align="left"><span>{user.is_admin ? <CheckIcon /> : ''}</span></StyledTableCell>
                <StyledTableCell align="left"><span>{user.active ? <CheckIcon /> : ''}</span></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NavLink to="/CreateUser" ><Button style={{ marginTop: 40 }} variant="contained">Add New User</Button></NavLink>
    </div>
  )
}

