import React , { useState } from 'react'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";


const axios = require('axios').default;

export default function LogoUpload(props) {
    const [file, setFile] = useState()
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate();
    console.log(props.value)


    function handleChange(event) {
      setFile(event.target.files[0])
    }

    function handleSubmit(event) {
      
      event.preventDefault()
      const url = 'https://cma-python-api.scottiplaw.com/api/upload_logo';
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', file.name);
      formData.append('TENANT', props.value);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios.post(url, formData, config).then((response) => {
        console.log(response.data);
        setLoading(true)
      })
      .catch(function (error){
        console.log(error)
      });
      setTimeout(function(){
        navigate('/clients/'+ props.value);
        window.location.reload()
     }, 1000);
    }
  
    const bypassCache = Math.random() * 1000

    return (
     <div className="top-container">
       <div className="App" >
          <form onSubmit={handleSubmit}>
            <h4 style={{textTransform: 'capitalize'}}>{props.value}</h4>
            <h5 style={{margin: 20}}>Logo Upload </h5>
            <div className="upload-form">
            <input type="file" onChange={handleChange} style={{margin: 20, border: '1px black dashed', height: 200, width: 250, borderRadius: 5, padding: 70}} />
            <p>click "Choose File" or drag file into Square</p>
            </div>
            <button variant='outlined' type="submit" style={{border: '1px #1e88e5 solid', background: 'white', borderRadius: 5, marginLeft: 10, width: 150}}> <FileUploadIcon style={{color: '#20c997'}}/>Upload</button>
            {loading?<Box sx={{ display: 'flex' }} style={{marginLeft: '48%', marginTop: 20}}>
            <CircularProgress />
            </Box>: ""}
          </form>
          <p style={{marginTop: 40}}>Current Logo</p>
            <img  src={`https://cmablob.blob.core.windows.net/${props.value}/logo.png?${bypassCache}`} alt="logo" style={{width: '30%', height: '30%'}}></img>
    
      </div>
     </div>
    );
}

