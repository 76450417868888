import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { NavLink } from "react-router-dom";


export default function OpenPrConfirmDialog(props) {
  console.log("ConfirmDialog", props.owner)
  console.log("ConfirmDialog", props.repo)


  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpen(false)}

        >
          Cancel
        </Button>
        <Button
          component={NavLink} 
          to={`edit-agreement/${props.owner}/${props.repo}`}
          variant="outlined"
          onClick={() => {
            setOpen(false);
            onConfirm(props.owner, props.repo);
          }}
          style={{marginLeft: 10}}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
