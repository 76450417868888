import React from "react";
import { useMsal } from "@azure/msal-react";


export const ProfileContent = () => {
    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;

    return (
        <div className="token-request-card">
            <h5 className="card-title">Welcome {name}</h5>
        </div>
    );
};
